import { eventChannel, END } from 'redux-saga';
import { webSocketService } from 'services/WebSocketService';
import { Notifications } from 'types';

export function createWebSocketChannel(args: Notifications[]) {
  return eventChannel((emitter) => {
    const removeListener = webSocketService.onMessage((notification) => {
      emitter(notification);
    }, args[0]);

    return () => {
      removeListener();
      emitter(END);
    };
  });
}

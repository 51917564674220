import fontService, { FontWeight } from 'services/FontService';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const Message = styled.div`
  ${fontService.text({ size: 24, weight: FontWeight.medium })};
  ${({ theme }) => css`
    color: ${theme.text.primaryDefault};
  `}

  &:not(:first-of-type) {
    margin-top: 4px;
  }

  &:nth-child(1) {
    opacity: 0.3;
  }

  &:nth-child(2) {
    opacity: 0.4;
  }

  &:nth-child(3) {
    opacity: 0.6;
  }
`;

export const MessageAuthor = styled.span``;

export const MessageText = styled.span``;

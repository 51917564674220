import { Notifications, ClientSideNotifications, ErrorNotifications } from 'types';

export const defaultListeners = Object.keys(Notifications).reduce(
  (acc, cur) => ({
    ...acc,
    [cur]: [],
  }),
  {},
);

export const clientSideConnectionListeners = Object.values(ClientSideNotifications).reduce(
  (acc, cur) => ({
    ...acc,
    [cur]: [],
  }),
  {},
);

export const errorListeners = Object.values(ErrorNotifications).reduce(
  (acc, cur) => ({
    ...acc,
    [cur]: [],
  }),
  {},
);
